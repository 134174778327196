<template>
    <b-modal
        v-model="showModal"
        header-bg-variant="info"
        size="xl"
        :footer-class="['inventory-lookup-footer', 'd-flex', {'bg-light': showPrompt}]"
        header-class="inventory-lookup-header flex-wrap flex-column flex-md-row  pt-3 py-0"
        dialog-class="modal-dialog-centered"
        body-class="p-0"
        no-close-on-backdrop
        no-close-on-esc
    >
        <template #modal-header>
            <div class="align-middle text-center text-md-left pb-2">
                <label
                    class="d-block d-md-inline text-big heavy text-white pl-0 side-text align-middle"
                    >{{ side }}</label
                >
                <b-img
                    :src="icon"
                    class="d-block d-md-inline m-auto mx-md-3 pb-2 pt-3 py-md-0"
                ></b-img>
                <label class="d-block d-md-inline text-title heavy text-white align-middle">
                    {{ t('invLookup_title') }}
                </label>
            </div>
            <div class="text-white text-title pb-2">
                <b-tabs v-model="tabIndex" pills>
                    <b-tab :title="t('staarName')" title-link-class="text-white"></b-tab>
                    <b-tab
                        v-if="isSpheric && inventoryResults.hasConsignmentInventory"
                        :title="t('invLookup_Consignment')"
                        title-link-class="text-white"
                    ></b-tab>
                </b-tabs>
            </div>
            <b-form-group
                class="mb-0 pb-2"
                :label="t('targetLens')"
                label-class="text-white"
                label-for="description"
                label-cols="12"
                label-cols-sm
            >
                <b-form-input
                    v-model="targetLensDescription"
                    class="text-body font-weight-bolder w-360px"
                    disabled
                ></b-form-input>
            </b-form-group>
        </template>

        <template #default>
            <b-table
                sticky-header="538px"
                :items="inventoryData"
                :fields="fields"
                class="inventory-table"
                :emptyText="t('invLookup_NoLenses')"
                show-empty
            >
                <template #empty="scope">
                    <b-overlay :show="true" no-wrap>
                        <template #overlay>
                            <div class="text-center p-3">
                                <h3>
                                    <strong>{{ scope.emptyText }}</strong>
                                </h3>
                                <template v-for="reason in noLensReasonsKeys">
                                    <h4 :key="reason" v-if="inventoryResults.noLensReasons[reason]">
                                        <strong
                                            ><div v-html="t(`invLookup_${reason}`)"></div
                                        ></strong>
                                    </h4>
                                </template>
                            </div>
                        </template>
                    </b-overlay>
                </template>
                <template #table-colgroup="scope">
                    <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{width: colWidths[field.key]}"
                    />
                </template>

                <template #cell(version)="{item}">
                    <span class="text-nowrap" :style="{color: item.color}">{{ item.version }}</span>
                </template>

                <template #cell(quantity)="{item}">
                    <div
                        class="min-w-82px"
                        v-if="isInStatus(item, [LENS_STATUS.IN_CART, LENS_STATUS.RESERVED])"
                    >
                        {{ item.quantity }}
                    </div>
                    <b-form-select
                        v-else
                        :options="quantityOptions"
                        :value="item.quantity"
                        :disabled="isMaxSelectableReached"
                        @change="cleanQuantities(item, $event)"
                        size="sm"
                        class="min-w-82px"
                    >
                        <template #first>
                            <b-form-select-option :value="0">{{
                                t('select')
                            }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </template>

                <template #cell(status)="{item}">
                    <div v-if="isStaarTabActive && item.isPrimaryLens" class="ml-2 primary-marker">
                        P
                    </div>
                </template>

                <template #cell(buttons)="{item}">
                    <div class="d-inline-flex">
                        <b-button
                            v-if="
                                !isManufactureViewEnabled && !isConsignmentTabActive && canAddToCart
                            "
                            size="sm"
                            class="my-1 mr-3 btn-block-lg-down text-nowrap min-w-92px"
                            :variant="
                                isInStatus(item, [LENS_STATUS.IN_CART, LENS_STATUS.RESERVED])
                                    ? 'primary-dark'
                                    : 'primary'
                            "
                            :disabled="
                                cartButtonsDisabled(item) ||
                                isInStatus(item, [LENS_STATUS.IN_CART, LENS_STATUS.RESERVED])
                            "
                            @click="addToCart(item)"
                        >
                            {{ cartButtonText(item) }}</b-button
                        >
                        <b-button
                            v-if="!isManufactureViewEnabled && canReserve"
                            :class="[
                                'my-1 btn-block-lg-down text-nowrap min-w-72px',
                                {'btn-primary': cartButtonsDisabled(item)},
                            ]"
                            size="sm"
                            :variant="
                                isInStatus(item, [null, LENS_STATUS.AVAILABLE])
                                    ? 'primary-dark'
                                    : 'gray-dark'
                            "
                            :disabled="cartButtonsDisabled(item)"
                            @click="reserveOrCancel(item)"
                            >{{ reserveOrCancelButtonText(item) }}</b-button
                        >
                        <b-button
                            v-if="isManufactureViewEnabled"
                            class="my-1 btn-block-lg-down text-nowrap"
                            size="sm"
                            disabled
                            block
                            variant="secondary"
                            >{{ t('invLookup_NotInStock') }}</b-button
                        >
                    </div>
                </template>
            </b-table>

            <div v-if="isManufactureViewEnabled" class="manufacture-overley">
                <div class="content">
                    <div class="info">
                        <p class="mb-0">
                            {{ t('invLookup_NoInStockBasedOnInput') }}
                        </p>
                        <p>{{ t('invLookup_LensMustBeMade') }}.</p>
                    </div>
                    <div class="mt-2">
                        <label class="w-100">{{ t('invLookup_Quantity') }}</label>
                        <b-form-select
                            :options="manufactureQuantityOptions"
                            v-model="manufactureQuantity"
                            size="sm"
                            class="w-auto"
                        >
                        </b-form-select>
                    </div>
                </div>
            </div>

            <b-overlay :show="showPrompt" no-wrap>
                <template #overlay>
                    <div
                        ref="dialog"
                        tabindex="-1"
                        role="dialog"
                        aria-modal="false"
                        aria-labelledby="form-confirm-label"
                        class="text-center p-3"
                    >
                        <div v-html="t('invLookup_CartCancel')"></div>

                        <div class="d-flex center">
                            <b-button
                                variant="outline-danger"
                                size="lg"
                                class="mr-3"
                                @click="showPrompt = false"
                            >
                                {{ t('no') }}
                            </b-button>
                            <b-button
                                variant="outline-success"
                                size="lg"
                                @click="onConfirmCancelation"
                            >
                                {{ t('yes') }}
                            </b-button>
                        </div>
                    </div>
                </template>
            </b-overlay>
        </template>

        <template #modal-footer>
            <b-container fluid :class="{invisible: showPrompt}">
                <b-row>
                    <b-col class="py-1 align-self-center" md="5">
                        <div class="d-flex justify-content-between">
                            <div
                                v-if="showManufactureButton && !isDistributorSurgeonMode"
                                class="align-self-center"
                            >
                                <b-button
                                    class="float-right float-sm-left"
                                    :disabled="manufactureLensDisabled"
                                    variant="purple"
                                    @click="showManufactureView"
                                    >{{ t('invLookup_ManufactureLens') }}
                                    <b-icon icon="gear-fill" class="ml-2"></b-icon
                                ></b-button>
                            </div>
                            <div class="align-self-center" :class="{'ml-3': showManufactureButton}">
                                <span class="text-caption heavy text-muted mr-2">
                                    {{ t('preop_SelDiffLength') }}
                                </span>
                                <b-form-select
                                    :value="selectedLength"
                                    :options="lengthOptions"
                                    :disabled="isMaxSelectableReached"
                                    @change="selectedLengthChange"
                                    class="text-body light w-auto"
                                >
                                </b-form-select>
                            </div>
                        </div>
                    </b-col>
                    <b-col class="py-1 align-self-center" md="2">
                        <p
                            v-if="isLimitedQuantity"
                            class="text-center align-middle font-weight-bold d-inline"
                        >
                            {{ t('inventory_backOrderNote') }}
                        </p>
                    </b-col>
                    <b-col class="py-1 align-self-center" md="5">
                        <div class="float-right text-right">
                            <b-button
                                id="printReport"
                                @click="printReport"
                                class="pointer ml-auto mr-2"
                            >
                                {{ t('inventoryPrintReport') }}
                                <b-icon class="pointer ml-2" icon="printer-fill"></b-icon>
                            </b-button>
                            <p
                                class="mb-0 text-center align-middle font-weight-bold mr-3 d-inline"
                                :class="{'text-danger': isTimeAlmostOver}"
                            >
                                <b-icon class="mr-1" icon="clock" />
                                {{ timer }} {{ t('inventory_remaining') }}
                            </p>
                            <b-button class="my-1 mr-1" @click="onBackOrCancel">{{
                                backButtonText
                            }}</b-button>
                            <b-button
                                v-if="!isManufactureViewEnabled"
                                :disabled="!wasOperationPerfomed"
                                @click="done"
                                variant="primary"
                                >{{ t('inventory_Done') }} <b-icon icon="check"></b-icon
                            ></b-button>
                            <b-button
                                v-if="isManufactureViewEnabled"
                                class="my-1"
                                variant="purple"
                                @click="manufactureLens"
                                >{{ t('invLookup_ManufactureLens') }}</b-button
                            >
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </template>
    </b-modal>
</template>
<script>
import {mapState, mapGetters, mapActions} from 'vuex';
import get from 'lodash/get';
import minBy from 'lodash/minBy';
import findLastIndex from 'lodash/findLastIndex';
import {
    formatLensNum,
    padNumber,
    formatNumber,
    decimalSeparatorFormatter,
} from '@/utilities/formatters';
import {LensModelCodes, DefaultManufactureInventory} from '@/store/modules/preopdata';
import ODIcon from '@/assets/preop-data/white-od-eyes.svg';
import OSIcon from '@/assets/preop-data/white-os-eyes.svg';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

const LENS_TAB_INDEXES = {
    STAAR: 0,
    CONSIGNMENT: 1,
};

const LENS_SOURCES = {
    STAAR: 1,
    CONSIGNMENT: 2,
};

const LENS_STATUS = {
    AVAILABLE: 'AVAILABLE',
    IN_CART: 'IN_CART',
    RESERVED: 'RESERVED',
};

const NO_LENS_REASONS_KEYS = [
    'sphereNotInRegCountryDiopterRange',
    'seqNotInRegCountryDiopterRange',
    'cylinderNotInRegCountryCylinderRange',
    'noRegCountryRowExists',
];

const MINUTES_TO_ALERT_TIME = 3;
const MAX_INVENTORY_ITEMS = 10;
const MAX_MANUFACTURE_LENS_IN_CART = 2;

export default {
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        preOpDataId: {
            type: Number,
            default: null,
        },
        lensInformation: {
            type: Object,
            default: () => ({}),
        },
        lengthOptions: {
            type: Array,
            default: () => [],
        },
        selectedLengthOption: {
            type: Number,
            default: () => null,
        },
        targetLensDescription: {
            type: String,
            default: null,
        },
        side: {
            type: String,
            default: null,
        },
        allLensInfo: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            ODIcon,
            OSIcon,
            LENS_STATUS,
            tabIndex: LENS_TAB_INDEXES.STAAR,
            noLensReasonsKeys: NO_LENS_REASONS_KEYS,
            manufactureQuantity: MAX_MANUFACTURE_LENS_IN_CART,
            lensesAdded: [],
            selectedLength: null,
            isManufactureViewEnabled: false,
            showPrompt: false,
            timerInterval: null,
            minutes: 0,
            seconds: 0,
            colWidths: {
                lensId: '100px',
                model: '150px',
                version: '300px',
                delivery: '150px',
                sphere: '100px',
                cylinder: '100px',
                axis: '100px',
                expectedRef: '170px',
                expectedSeq: '100px',
                quantity: '100px',
                buttons: '300px',
            },
        };
    },
    watch: {
        showModal(newValue) {
            if (newValue) {
                this.startTimer(60 * 20);
                this.selectedLength = this.selectedLengthOption;
            } else {
                clearInterval(this.timerInterval);
            }
        },
    },
    computed: {
        ...mapGetters('user', ['currentUser', 'activeCustomer']),
        ...mapGetters('permissions', ['permissions']),
        ...mapState({
            inventoryResults(state) {
                localStorage.inventoryLookupData = JSON.stringify({
                    preOpDataSetId: state.preopdata.preopdata.preOpDataSetId,
                    inventoryResults: {[this.side]: state.preopdata.inventoryResults[this.side]},
                });

                return get(state.preopdata.inventoryResults, this.side, {});
            },
            primaryLensOD(state) {
                return state.patient.currentPatientPrimaryLensOD;
            },
            primaryLensOS(state) {
                return state.patient.currentPatientPrimaryLensOS;
            },
        }),

        canAddToCart() {
            return this.checkPermissions({
                [PERMISSIONS.ORDERING]: [PERMISSIONS_VALUES.READ_WRITE],
            });
        },

        canReserve() {
            return this.checkPermissions({
                [PERMISSIONS.RESERVATIONS]: [PERMISSIONS_VALUES.READ_WRITE],
            });
        },

        isDistributorSurgeonMode() {
            return this.checkPermissions({
                [PERMISSIONS.DISTRIBUTOR_MODE]: [PERMISSIONS_VALUES.SURGEON],
            });
        },

        manufactureInfo() {
            return get(this.inventoryResults, 'lenses', []).find(
                (x) => x.isMto && x.status === LENS_STATUS.AVAILABLE
            );
        },
        /**
         * Sets the primary lens status of the inventory data.
         *
         * If there is no primary lens for this eye (no lens has been previously
         * ordered or added to the cart), set the lens with the oldest add-to-cart
         * value as the primary lens.
         */
        inventoryData() {
            switch (this.tabIndex) {
                case LENS_TAB_INDEXES.STAAR:
                    if (
                        this[`primaryLens${this.side.toUpperCase()}`] === null &&
                        this.staarInventory.length > 0
                    ) {
                        let primaryLens = minBy(this.staarInventory, 'addedToCart');
                        this.staarInventory.forEach((lens) => {
                            lens['isPrimaryLens'] = lens === primaryLens;
                        });
                    }
                    return this.staarInventory;
                case LENS_TAB_INDEXES.CONSIGNMENT:
                    // Consignment lenses don't use the primary status
                    this.consignmentInventory.forEach((lens) => {
                        lens['isPrimaryLens'] = null;
                    });
                    return this.consignmentInventory;
                default:
                    return this.staarInventory;
            }
        },

        staarInventory() {
            if (this.isManufactureViewEnabled) {
                return [{...DefaultManufactureInventory(), ...this.manufactureInfo}];
            } else {
                let lensInventory = get(this.inventoryResults, 'lenses', []).filter(
                    (x) => !x.isMto || x.status === LENS_STATUS.IN_CART
                );
                lensInventory.forEach((lens) => {
                    //find the lens in allLensInfo
                    //and update the expected Ref & SEQ fields
                    let cyl = lens.cylinder;
                    let sphere = lens.sphere;
                    let section = this.allLensInfo?.find((v) => v.cylinder == cyl);
                    if (section) {
                        //with the section, find the index that matches the sphere value we have
                        let sphereIndex = section.sphere.findIndex((v) => v == sphere);
                        //use this index to build the ExpRef and ExpSeq
                        if (sphereIndex) {
                            lens.expectedSeq = section.expSeq[sphereIndex];
                        }
                    }
                });

                /**When we add MTO lenses there is the possibility that the last lenses are added as reserved
                 * or added to the cart, they will be hidden if their positions are not exchanged. */
                lensInventory
                    .slice(MAX_INVENTORY_ITEMS)
                    .filter((x) => [LENS_STATUS.IN_CART, LENS_STATUS.RESERVED].includes(x.status))
                    .forEach((lens) => {
                        const lensIndex = lensInventory.indexOf(lens);
                        const swapIndex = findLastIndex(
                            lensInventory.slice(0, MAX_INVENTORY_ITEMS),
                            (x) => x.status === LENS_STATUS.AVAILABLE
                        );
                        this.swapArrayLocs(lensInventory, lensIndex, swapIndex);
                    });
                return lensInventory.slice(0, MAX_INVENTORY_ITEMS);
            }
        },

        consignmentInventory() {
            const lensInventory = (this.inventoryResults.consignmentLenses || []).map((lens) => {
                lens.quantity = 1;
                //find the lens in allLensInfo
                //and update the expected Ref & SEQ fields
                const {cyl, sphere} = lens;
                let section = this.allLensInfo?.find((v) => v.cylinder == cyl);
                if (section) {
                    //with the section, find the index that matches the sphere value we have
                    let sphereIndex = section.sphere.findIndex((v) => v == sphere);
                    //use this index to build the ExpRef and ExpSeq
                    if (sphereIndex) {
                        lens.expectedSeq = this.formatLensNum(section.expSeq[sphereIndex], 2, 2);
                    }
                }
                return lens;
            });
            return lensInventory;
        },

        isToric() {
            return this.lensInformation.calculationType == LensModelCodes.TORIC;
        },

        isSpheric() {
            return this.lensInformation.calculationType == LensModelCodes.SPHERIC;
        },

        isStaarTabActive() {
            return this.tabIndex === LENS_TAB_INDEXES.STAAR;
        },

        isConsignmentTabActive() {
            return this.tabIndex === LENS_TAB_INDEXES.CONSIGNMENT;
        },

        isLimitedQuantity() {
            return this.staarInventory.some((lens) => lens.limitedQuantity);
        },

        icon() {
            return this[`${this.side}Icon`];
        },

        toricFields() {
            let toricFields = [
                {key: 'lensId', stickyColumn: true, label: this.t('serialNo')},
                {key: 'model', label: this.t('invLookupCol_Model')},
                {key: 'version', label: this.t('invLookupCol_Version')},
                {
                    key: 'sphere',
                    label: this.t('invLookupCol_Sphere'),
                    formatter: (value) =>
                        this.formatLensNum(value, 2, this.currentUser.decimalSeparator),
                },
                {
                    key: 'cylinder',
                    label: this.t('invLookupCol_Cylinder'),
                    formatter: (value) =>
                        this.formatLensNum(value, 2, this.currentUser.decimalSeparator),
                },
                {
                    key: 'axis',
                    label: this.t('invLookupCol_Axis'),
                    formatter: (value) =>
                        this.padNumber(value, 2, this.currentUser.decimalSeparator),
                },
                {
                    key: 'expectedRef',
                    label: this.t('invLookupCol_ExpRef'),
                    formatter: (value) =>
                        decimalSeparatorFormatter(value, this.currentUser.decimalSeparator),
                },
                {
                    key: 'expectedSeq',
                    label: this.t('invLookupCol_ExpSEQ'),
                    formatter: (value) =>
                        this.formatLensNum(value, 2, this.currentUser.decimalSeparator),
                },
                {key: 'delivery', label: this.t('invLookupCol_Delivery')},
            ];

            if (this.isStaarTabActive && this.activeCustomer.maxLenses > 1) {
                toricFields.push({key: 'status', label: this.t('invLookupCol_Status')});
            }

            toricFields.push({key: 'buttons', label: ''});

            return toricFields;
        },

        sphericFields() {
            const sphericFields = [
                {
                    key: 'model',
                    stickyColumn: !this.isConsignmentTabActive,
                    label: this.t('invLookupCol_Model'),
                },
                {key: 'version', label: this.t('invLookupCol_Version')},
                {
                    key: 'length',
                    label: this.t('preopreport_Length'),
                    formatter: (value) =>
                        `${formatNumber(value, 1, this.currentUser.decimalSeparator)} mm`,
                },
                {
                    key: 'sphere',
                    label: this.t('inventory_Diopter'),
                    formatter: (value) =>
                        this.formatLensNum(value, 2, this.currentUser.decimalSeparator),
                },
            ];
            // staar inventory need to set the quantity dropdown and can view delivery column
            if (this.isStaarTabActive) {
                sphericFields.push(
                    {key: 'delivery', label: this.t('invLookupCol_Delivery')},
                    {key: 'quantity', label: this.t('invLookup_Quantity')}
                );
                if (this.activeCustomer.maxLenses > 1) {
                    sphericFields.push({key: 'status', label: this.t('invLookupCol_Status')});
                }
            }
            // if consignment, insert the serial number as the first column
            if (this.isConsignmentTabActive) {
                sphericFields.unshift({
                    key: 'lensId',
                    stickyColumn: true,
                    label: this.t('serialNo'),
                });
            }
            sphericFields.push({key: 'buttons', label: ''});
            return sphericFields;
        },

        fields() {
            return this.isToric ? this.toricFields : this.sphericFields;
        },

        showManufactureButton() {
            return !this.isManufactureViewEnabled && this.isToric && this.canAddToCart;
        },

        wasOperationPerfomed() {
            return !!this.lensesAdded.length;
        },

        remainingSelectableLenses() {
            return this.inventoryResults.maxSelectable - this.quantitySelected;
        },

        quantityOptions() {
            const length = this.remainingSelectableLenses;
            return Array.from({length}, (_, i) => i + 1);
        },

        quantitySelected() {
            return this.lensesAdded
                .filter((lens) => lens.lensSourceId == LENS_SOURCES.STAAR)
                .reduce((acc, lens) => acc + lens.quantity, 0);
        },

        isMaxSelectableReached() {
            return this.quantitySelected >= this.inventoryResults.maxSelectable;
        },

        cartButtonsDisabled() {
            return (item) => {
                const isSelected = this.lensesAdded.some((x) => x.lensId === item.lensId);
                return !item.quantity || (!isSelected && this.isMaxSelectableReached);
            };
        },

        isInStatus() {
            return (item, statusValues) => statusValues.includes(item.status);
        },

        cartButtonText() {
            return (item) => {
                switch (item.status) {
                    case LENS_STATUS.IN_CART:
                        return this.t('inventory_InCart');
                    case LENS_STATUS.RESERVED:
                        return this.t('inventory_Reserved');
                    case LENS_STATUS.AVAILABLE:
                    default:
                        return this.t('inventory_AddToCart');
                }
            };
        },

        reserveOrCancelButtonText() {
            return (item) => {
                switch (item.status) {
                    case LENS_STATUS.IN_CART:
                    case LENS_STATUS.RESERVED:
                        return this.t('inventory_Cancel');
                    case LENS_STATUS.AVAILABLE:
                    default:
                        return this.t('inventory_Reserve');
                }
            };
        },

        backButtonText() {
            return this.wasOperationPerfomed
                ? this.t('inventory_Cancel')
                : this.t('inventory_Back');
        },

        timer() {
            const minutes = this.minutes < 10 ? '0' + this.minutes : this.minutes;
            const seconds = this.seconds < 10 ? '0' + this.seconds : this.seconds;

            return `${minutes}:${seconds}`;
        },

        isTimeAlmostOver() {
            return this.minutes <= MINUTES_TO_ALERT_TIME;
        },

        remainingManufactureLenses() {
            const {length: mtoLensesAdded = 0} = this.lensesAdded.filter((x) => x.isMto);
            return MAX_MANUFACTURE_LENS_IN_CART - mtoLensesAdded;
        },

        manufactureQuantityOptions() {
            /** If the manufacture lenses that can be added to the cart exceed the number of selectable lenses
             * that can be added to the cart, we must subtract the amounts so as not to exceed the maximum selectable. */
            const length =
                this.remainingManufactureLenses > this.remainingSelectableLenses
                    ? this.remainingManufactureLenses - this.remainingSelectableLenses
                    : this.remainingManufactureLenses;
            return Array.from({length}, (_, i) => i + 1);
        },

        manufactureLensDisabled() {
            const {length: mtoLensesAdded = 0} = this.lensesAdded.filter((x) => x.isMto);
            return (
                !this.manufactureInfo ||
                this.isMaxSelectableReached ||
                mtoLensesAdded >= MAX_MANUFACTURE_LENS_IN_CART
            );
        },
    },

    methods: {
        formatLensNum,
        padNumber,
        ...mapActions({
            unlockLenses: 'preopdata/unlockLenses',
            primaryLens: 'patient/primaryLens',
        }),
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        swapArrayLocs(arr, index1, index2) {
            [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
        },
        startTimer(duration) {
            let timer = duration;
            const timerRegression = () => {
                this.minutes = parseInt(timer / 60, 10);
                this.seconds = parseInt(timer % 60, 10);
                if (--timer < 0) {
                    this.onConfirmCancelation();
                }
            };
            timerRegression();
            this.timerInterval = setInterval(timerRegression, 1000);
        },
        cleanQuantities(item, value) {
            item.quantity = value;
            this.staarInventory
                .filter(
                    (lens) =>
                        lens.lensId !== item.lensId &&
                        lens.status == LENS_STATUS.AVAILABLE &&
                        lens.quantity > 0
                )
                .forEach((item) => (item.quantity = 0));
        },
        onConfirmCancelation() {
            this.lensesAdded = [];
            this.showPrompt = false;
            this.onBackOrCancel();
        },
        onBackOrCancel() {
            if (this.isManufactureViewEnabled) {
                this.isManufactureViewEnabled = false;
                return;
            }

            if (this.wasOperationPerfomed) {
                this.showPrompt = true;
                return;
            }

            this.unlockLenses(this.preOpDataId);
            this.$emit('update:showModal', false);
        },
        addToCart(item) {
            const isAdded = [LENS_STATUS.IN_CART, LENS_STATUS.RESERVED].includes(item.status);
            if (!isAdded) {
                item.status = LENS_STATUS.IN_CART;
                item.addedToCart = new Date();
                this.lensesAdded.push(item);
            }
        },
        reserveOrCancel(item) {
            const isAdded = [LENS_STATUS.IN_CART, LENS_STATUS.RESERVED].includes(item.status);
            // Cancel
            if (isAdded) {
                if (item.status === LENS_STATUS.IN_CART) {
                    item.addedToCart = null;
                }
                // default lens quantity should be 1 if is toric lens or spherical with consignment source
                // otherwise must be zero to be able to select the quantity from quantity selector
                item.quantity =
                    this.isToric || item.lensSourceId == LENS_SOURCES.CONSIGNMENT ? 1 : 0;
                item.status = LENS_STATUS.AVAILABLE;
                const indexAdded = this.lensesAdded.indexOf(item);
                this.lensesAdded.splice(indexAdded, 1);
                /* MTO lenses are added to inventory list when manufacture button get clicked
                 We need to remove them from inventory list when cancel button get clicked */
                if (item.isMto) {
                    const indexInventory = this.inventoryResults.lenses.indexOf(item);
                    this.inventoryResults.lenses.splice(indexInventory, 1);
                }
            } else {
                item.status = LENS_STATUS.RESERVED;
                this.lensesAdded.push(item);
            }
        },
        selectedLengthChange(selectedLength) {
            const payload = {
                selectedLength,
                pendingAddToCart: this.lensesAdded.filter((i) => i.status === LENS_STATUS.IN_CART),
                pendingReserve: this.lensesAdded.filter((i) => i.status === LENS_STATUS.RESERVED),
            };
            this.$emit('on-selected-length-change', payload);
            this.selectedLength = selectedLength;
        },
        showManufactureView() {
            this.isManufactureViewEnabled = true;
            this.manufactureQuantity = this.manufactureQuantityOptions.length;
        },
        manufactureLens() {
            if (!this.manufactureQuantity) return;
            for (let index = 0; index < this.manufactureQuantity; index++) {
                const mtoLens = {...DefaultManufactureInventory(), ...this.manufactureInfo};
                this.addToCart(mtoLens);
                this.inventoryResults.lenses.unshift(mtoLens);
            }
            this.onBackOrCancel();
        },
        async done() {
            const payload = {
                calculationId: this.inventoryResults.calculationId,
                addToCart: this.lensesAdded.filter((i) => i.status === LENS_STATUS.IN_CART),
                reserve: this.lensesAdded.filter((i) => i.status === LENS_STATUS.RESERVED),
            };

            try {
                const {success, error} = await this.blockingRequest('cart/submitLenses', payload);
                if (success) {
                    await this.blockingRequest('cart/fetchItemsCount');
                    this.onConfirmCancelation();
                    this.$emit('on-locked');
                } else {
                    throw error;
                }
            } catch (error) {
                this.$bvToast.toast(error?.errors?.join(' ') || this.t('somethingWentWrong'), {
                    title: error?.errorCode || this.t('warning'),
                    variant: 'warning',
                    solid: true,
                });
            }
        },
        printReport() {
            const routeData = this.$router.resolve({
                name: 'InventoryLookupReport',
            });

            window.open(routeData.href, '_blank');
        },
    },
};
</script>
<style lang="scss" scoped>
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import '../assets/css/variables';

@include media-breakpoint-up(sm) {
    .w-360px {
        width: 360px;
    }
}

@media (min-width: 768px) {
    ::v-deep .modal-xl {
        width: 90%;
        max-width: 75vw;
    }
}

::v-deep .inventory-lookup-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

::v-deep .inventory-lookup-header {
    align-items: center;
}

::v-deep .inventory-table td {
    padding: 0.3rem 1rem 0.3rem 1rem;
    vertical-align: middle;
    white-space: nowrap;
}

::v-deep .primary-marker {
    background-color: $primary;
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    text-align: center;
    color: #fff;
    line-height: 1.75rem;
}
::v-deep .modal-body {
    height: 580px; //calc(100vh - 200px);
    overflow-y: auto;
}

::v-deep .nav-pills .nav-link.active {
    background-color: #1e555c;
    &:focus {
        color: white !important; /* bootstrap override without it */
    }
}

.text-title {
    font-weight: bold;
    font-size: 1.14rem;
}

.manufacture-overley {
    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
    .info {
        font-size: 1.4rem;
        font-weight: bolder;
    }
}

.min-w-92px {
    min-width: 92px;
}

.min-w-72px {
    min-width: 72px;
}

#printReport {
    color: white;
    background-color: #616469;
}

.min-w-82px {
    min-width: 82px;
}
</style>
